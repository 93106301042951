import React from "react"
import VideoAnalytic from "@videos/feature-analytic.mp4"

export const FeatureAnalytics = () => {
  return (
    <section id="featureAnalytics" className="our-features__analytics">
      <div className="container">
        <div className="row flex-a-center">
          <div className="col-6">
            <h2 className="our-features__analytics__title heading-3">
              Analitik
            </h2>
            <div className="accordion" id="accordionAnalytics">
              <div className="our-features__accordion accordion-item">
                <div
                  className="our-features__accordion__header accordion-header"
                  id="headingEight"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="true"
                  aria-controls="collapseEight"
                >
                  <div className="our-features__accordion__toggle-icon">
                    <span className="mdi mdi-arrow-right"></span>
                  </div>
                  <button
                    className="our-features__accordion__button heading-6 accordion-button"
                    type="button"
                  >
                    Jumlah Pengunjung
                  </button>
                </div>
                <div
                  id="collapseEight"
                  className="our-features__accordion-collapse accordion-collapse collapse show"
                  aria-labelledby="headingEight"
                  data-bs-parent="#accordionAnalytics"
                >
                  <p className="body our-features__accordion__body accordion-body">
                    Di sini kamu bisa mengetahui banyak audiens yang mengunjungi website kamu dalam rentang waktu tertentu.
                  </p>
                </div>
              </div>
              <div className="our-features__accordion accordion-item">
                <div
                  className="our-features__accordion__header accordion-header collapsed"
                  id="headingNine"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  <div className="our-features__accordion__toggle-icon">
                    <span className="mdi mdi-arrow-right"></span>
                  </div>
                  <button
                    className="our-features__accordion__button heading-6 accordion-button"
                    type="button"
                  >
                    Analitik Feed
                  </button>
                </div>
                <div
                  id="collapseNine"
                  className="our-features__accordion-collapse accordion-collapse collapse"
                  aria-labelledby="headingNine"
                  data-bs-parent="#accordionAnalytics"
                >
                  <p className="body our-features__accordion__body accordion-body">
                    Data adalah elemen penting! Ketahui performa dari post yang kamu bagikan dengan analitik yang kami sediakan.
                  </p>
                </div>
              </div>
              <div className="our-features__accordion accordion-item">
                <div
                  className="our-features__accordion__header accordion-header collapsed"
                  id="headingTen"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTen"
                  aria-expanded="false"
                  aria-controls="collapseTen"
                >
                  <div className="our-features__accordion__toggle-icon">
                    <span className="mdi mdi-arrow-right"></span>
                  </div>
                  <button
                    className="our-features__accordion__button heading-6 accordion-button"
                    type="button"
                  >
                    Jumlah Klik Link
                  </button>
                </div>
                <div
                  id="collapseTen"
                  className="our-features__accordion-collapse accordion-collapse collapse"
                  aria-labelledby="headingTen"
                  data-bs-parent="#accordionAnalytics"
                >
                  <p className="body our-features__accordion__body accordion-body">
                    Ketahui jumlah klik yang kamu terima untuk link yang kamu pasang agar mempermudah untuk mengetahui kemana fokusmu harus diarahkan.
                  </p>
                </div>
              </div>
              <div className="our-features__accordion accordion-item">
                <div
                  className="our-features__accordion__header accordion-header collapsed"
                  id="headingEleven"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEleven"
                  aria-expanded="false"
                  aria-controls="collapseEleven"
                >
                  <div className="our-features__accordion__toggle-icon">
                    <span className="mdi mdi-arrow-right"></span>
                  </div>
                  <button
                    className="our-features__accordion__button heading-6 accordion-button"
                    type="button"
                  >
                    Jumlah Klik Media Sosial
                  </button>
                </div>
                <div
                  id="collapseEleven"
                  className="our-features__accordion-collapse accordion-collapse collapse"
                  aria-labelledby="headingEleven"
                  data-bs-parent="#accordionAnalytics"
                >
                  <p className="body our-features__accordion__body accordion-body">
                    Ketahui juga berapa banyak klik yang didapatkan oleh media sosialmu sehingga kamu bisa tahu media sosial apa yang paling banyak digunakan audiens kamu.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 our-features__video analytics-video">
            <video
              src={VideoAnalytic}
              height={285}
              autoPlay={true}
              muted={true}
              loop={true}
            ></video>
          </div>
        </div>
      </div>
    </section>
  )
}

export const FeatureAnalyticsMobile = () => {
  return (
    <section id="m-featureAnalytics" className="our-features__analytics">
      <div className="container">
        <div className="row flex-a-center">
          <div className="col-12">
            <h2 className="our-features__analytics__title heading-3">
              Analitik
            </h2>
          </div>
          <div className="col-12 our-features__video">
            <video
              src={VideoAnalytic}
              height="fit-content"
              autoPlay={true}
              muted={true}
              loop={true}
            ></video>
          </div>
          <div className="col-12">
            <div className="accordion" id="accordionAnalytics">
              <div className="our-features__accordion accordion-item">
                <div
                  className="our-features__accordion__header accordion-header"
                  id="headingEight"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="true"
                  aria-controls="collapseEight"
                >
                  <div className="our-features__accordion__toggle-icon">
                    <span className="mdi mdi-arrow-right"></span>
                  </div>
                  <button
                    className="our-features__accordion__button heading-6 accordion-button"
                    type="button"
                  >
                    Jumlah Pengunjung
                  </button>
                </div>
                <div
                  id="collapseEight"
                  className="our-features__accordion-collapse accordion-collapse collapse show"
                  aria-labelledby="headingEight"
                  data-bs-parent="#accordionAnalytics"
                >
                  <p className="body our-features__accordion__body accordion-body">
                    Di sini kamu bisa mengetahui banyak audiens yang mengunjungi website kamu dalam rentang waktu tertentu.
                  </p>
                </div>
              </div>
              <div className="our-features__accordion accordion-item">
                <div
                  className="our-features__accordion__header accordion-header collapsed"
                  id="headingNine"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  <div className="our-features__accordion__toggle-icon">
                    <span className="mdi mdi-arrow-right"></span>
                  </div>
                  <button
                    className="our-features__accordion__button heading-6 accordion-button"
                    type="button"
                  >
                    Analitik Feed
                  </button>
                </div>
                <div
                  id="collapseNine"
                  className="our-features__accordion-collapse accordion-collapse collapse"
                  aria-labelledby="headingNine"
                  data-bs-parent="#accordionAnalytics"
                >
                  <p className="body our-features__accordion__body accordion-body">
                    Data adalah elemen penting! Ketahui performa dari post yang kamu bagikan dengan analitik yang kami sediakan.
                  </p>
                </div>
              </div>
              <div className="our-features__accordion accordion-item">
                <div
                  className="our-features__accordion__header accordion-header collapsed"
                  id="headingTen"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTen"
                  aria-expanded="false"
                  aria-controls="collapseTen"
                >
                  <div className="our-features__accordion__toggle-icon">
                    <span className="mdi mdi-arrow-right"></span>
                  </div>
                  <button
                    className="our-features__accordion__button heading-6 accordion-button"
                    type="button"
                  >
                    Jumlah Klik Link
                  </button>
                </div>
                <div
                  id="collapseTen"
                  className="our-features__accordion-collapse accordion-collapse collapse"
                  aria-labelledby="headingTen"
                  data-bs-parent="#accordionAnalytics"
                >
                  <p className="body our-features__accordion__body accordion-body">
                    Ketahui jumlah klik yang kamu terima untuk link yang kamu pasang agar mempermudah untuk mengetahui kemana fokusmu harus diarahkan.
                  </p>
                </div>
              </div>
              <div className="our-features__accordion accordion-item">
                <div
                  className="our-features__accordion__header accordion-header collapsed"
                  id="headingEleven"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEleven"
                  aria-expanded="false"
                  aria-controls="collapseEleven"
                >
                  <div className="our-features__accordion__toggle-icon">
                    <span className="mdi mdi-arrow-right"></span>
                  </div>
                  <button
                    className="our-features__accordion__button heading-6 accordion-button"
                    type="button"
                  >
                    Jumlah Klik Media Sosial
                  </button>
                </div>
                <div
                  id="collapseEleven"
                  className="our-features__accordion-collapse accordion-collapse collapse"
                  aria-labelledby="headingEleven"
                  data-bs-parent="#accordionAnalytics"
                >
                  <p className="body our-features__accordion__body accordion-body">
                    Ketahui juga berapa banyak klik yang didapatkan oleh media sosialmu sehingga kamu bisa tahu media sosial apa yang paling banyak digunakan audiens kamu.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
