import React from "react"
import { Link } from "gatsby"
import { detectMob } from "@src/Helper"
import {
  FeaturePersonalize,
  FeaturePersonalizeMobile,
} from "./FeaturePersonalize"
import { FeatureLinks, FeatureLinksMobile } from "./FeatureLinks"
import { FeatureMediaPost, FeatureMediaPostMobile } from "./FeatureMediaPost"
import {
  FeatureSocialLinks,
  FeatureSocialLinksMobile,
} from "./FeatureSocialLinks"
import { FeatureAnalytics, FeatureAnalyticsMobile } from "./FeatureAnalytics"
import { FeatureGetstarted, FeatureGetstartedMobile } from "./FeatureGetstarted"
import Personalize from "@images/features/features-personalize.svg"
import Analytic from "@images/features/features-analytic.svg"
import Feed from "@images/features/features-feed.svg"
import Links from "@images/features/features-link.svg"
import Sosmed from "@images/features/features-sosmed.svg"

const Features = () => {
  if (detectMob()) {
    return (
      <>
        <div id="m-ourFeatures" className="our-features">
          <div className="container pad-t-90">
            <h1 className="our-features__title heading-1">Apa itu Pipehome?</h1>
            <h3 className="our-features__description subhead">
              Pipehome merupakan micro website yang bisa kamu manfaatkan untuk
              memajang <span className="text-700">semua produk</span> dan{" "}
              <span className="text-700">portofolio</span> kamu dari berbagai
              website{" "}
              <span className="text-700">
                ke dalam satu <i>link</i>
              </span>
              .
            </h3>
            <div className="our-features__list row">
              <div className="our-features__list__item col-12 mt-4">
                <img
                  src={Personalize}
                  alt="Fitur Pipehome"
                  width={48}
                  height={48}
                />
                <h3 className="heading-6">
                  <em>Edit Profile</em>
                </h3>
                <h4 className="body">Cantumkan informasi personalmu disini.</h4>
                <Link
                  className="caption text-700 text-apricot-60 text-decoration-none"
                  to="/our-features#m-featurePersonalize"
                >
                  Pelajari lebih lanjut <span className="mdi mdi-arrow-right" />
                </Link>
              </div>
              <div className="our-features__list__item col-12 mt-4">
                <img src={Links} alt="Fitur Pipehome" width={48} height={48} />
                <h3 className="heading-6">
                  <i>Links</i>
                </h3>
                <h4 className="body">
                  Pasang <i>link</i> sesukamu.
                </h4>
                <Link
                  className="caption text-700 text-apricot-60 text-decoration-none"
                  to="/our-features#m-featureLinks"
                >
                  Pelajari lebih lanjut <span className="mdi mdi-arrow-right" />
                </Link>
              </div>
              <div className="our-features__list__item col-12 mt-4">
                <img src={Sosmed} alt="Fitur Pipehome" width={48} height={48} />
                <h3 className="heading-6">Media Sosial</h3>
                <h4 className="body">
                  Semakin dekat dengan audiens dengan media sosial.
                </h4>
                <Link
                  className="caption text-700 text-apricot-60 text-decoration-none"
                  to="/our-features#m-featureSocialLinks"
                >
                  Pelajari lebih lanjut <span className="mdi mdi-arrow-right" />
                </Link>
              </div>
              <div className="our-features__list__item col-12 mt-4">
                <img src={Feed} alt="Fitur Pipehome" width={48} height={48} />
                <h3 className="heading-6">Feed</h3>
                <h4 className="body">
                  Atur post mu sesuai keinginan dan tambahkan kategori
                </h4>
                <Link
                  className="caption text-700 text-apricot-60 text-decoration-none"
                  to="/our-features#m-featureMediaPost"
                >
                  Pelajari lebih lanjut <span className="mdi mdi-arrow-right" />
                </Link>
              </div>
              <div className="our-features__list__item col-12 mt-4">
                <img
                  src={Analytic}
                  alt="Fitur Pipehome"
                  width={48}
                  height={48}
                />
                <h3 className="heading-6">Analitik</h3>
                <h4 className="body">
                  Cek analitik <i>real time</i> mu kapan saja.
                </h4>
                <Link
                  className="caption text-700 text-apricot-60 text-decoration-none"
                  to="/our-features#m-featureAnalytics"
                >
                  Pelajari lebih lanjut <span className="mdi mdi-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
          <FeaturePersonalizeMobile />
          <FeatureLinksMobile />
          <FeatureMediaPostMobile />
          <FeatureSocialLinksMobile />
          <FeatureAnalyticsMobile />
          <FeatureGetstartedMobile />
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="our-features">
          <div className="container pad-t-150">
            <h1 className="our-features__title heading-1">Apa itu Pipehome?</h1>
            <h3 className="our-features__description subhead">
              Pipehome merupakan micro website yang memudahkan kamu dalam{" "}
              <span className="text-700">membagikan produk</span>,{" "}
              <span className="text-700">portofolio</span> dan{" "}
              <span className="text-700">konten</span> kamu dari berbagai
              website{" "}
              <span className="text-700">
                dengan hanya satu <i>link</i>
              </span>
              .
            </h3>
            <div className="our-features__list row">
              <div className="our-features__list__item col-4">
                <img
                  src={Personalize}
                  alt="Fitur Pipehome"
                  width={48}
                  height={48}
                />
                <h3 className="heading-6">
                  <em>Edit Profile</em>
                </h3>
                <h4 className="body">Cantumkan informasi personalmu disini.</h4>
                <Link
                  className="caption text-700 text-apricot-60 text-decoration-none"
                  to="/our-features#featurePersonalize"
                >
                  Pelajari lebih lanjut <span className="mdi mdi-arrow-right" />
                </Link>
              </div>
              <div className="our-features__list__item col-4">
                <img src={Links} alt="Fitur Pipehome" width={48} height={48} />
                <h3 className="heading-6">
                  <i>Links</i>
                </h3>
                <h4 className="body">
                  Pasang <i>link</i> sesukamu.
                </h4>
                <Link
                  className="caption text-700 text-apricot-60 text-decoration-none"
                  to="/our-features#featureLinks"
                >
                  Pelajari lebih lanjut <span className="mdi mdi-arrow-right" />
                </Link>
              </div>
              <div className="our-features__list__item col-4">
                <img src={Sosmed} alt="Fitur Pipehome" width={48} height={48} />
                <h3 className="heading-6">Media Sosial</h3>
                <h4 className="body">
                  Semakin dekat dengan audiens dengan media sosial.
                </h4>
                <Link
                  className="caption text-700 text-apricot-60 text-decoration-none"
                  to="/our-features#featureSocialLinks"
                >
                  Pelajari lebih lanjut <span className="mdi mdi-arrow-right" />
                </Link>
              </div>
              <div className="our-features__list__item col-4">
                <img src={Feed} alt="Fitur Pipehome" width={48} height={48} />
                <h3 className="heading-6">Feed</h3>
                <h4 className="body">
                  Atur post mu sesuai keinginan dan tambahkan kategori
                </h4>
                <Link
                  className="caption text-700 text-apricot-60 text-decoration-none"
                  to="/our-features#featureMediaPost"
                >
                  Pelajari lebih lanjut <span className="mdi mdi-arrow-right" />
                </Link>
              </div>
              <div className="our-features__list__item col-4">
                <img
                  src={Analytic}
                  alt="Fitur Pipehome"
                  width={48}
                  height={48}
                />
                <h3 className="heading-6">Analitik</h3>
                <h4 className="body">
                  Cek analitik <i>real time</i> mu kapan saja.
                </h4>
                <Link
                  className="caption text-700 text-apricot-60 text-decoration-none"
                  to="/our-features#featureAnalytics"
                >
                  Pelajari lebih lanjut <span className="mdi mdi-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
          <FeaturePersonalize />
          <FeatureLinks />
          <FeatureMediaPost />
          <FeatureSocialLinks />
          <FeatureAnalytics />
          <FeatureGetstarted />
        </div>
      </>
    )
  }
}

export default Features
