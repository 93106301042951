import React from "react"
import VideoMediaPost from "@videos/feature-media-post.mp4"

export const FeatureMediaPost = () => {
  return (
    <section id="featureMediaPost" className="our-features__media-post">
      <div className="container">
        <div className="row flex-a-center">
          <div className="col-6">
            <h2 className="our-features__media-post__title heading-3">
              Feed
            </h2>
            <div className="accordion" id="accordionMediaPost">
              <div className="our-features__accordion accordion-item">
                <div
                  className="our-features__accordion__header accordion-header"
                  id="headingFive"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="true"
                  aria-controls="collapseFive"
                >
                  <div className="our-features__accordion__toggle-icon">
                    <span className="mdi mdi-arrow-right"></span>
                  </div>
                  <button
                    className="our-features__accordion__button heading-6 accordion-button"
                    type="button"
                  >
                    Kategorikan Postingan Kamu
                  </button>
                </div>
                <div
                  id="collapseFive"
                  className="our-features__accordion-collapse accordion-collapse collapse show"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionMediaPost"
                >
                  <p className="body our-features__accordion__body accordion-body">
                    Punya banyak post di media sosial dan sulit untuk mengkategorikannya? Disini, kendala itu dapat diselesaikan dengan mudah!
                  </p>
                </div>
              </div>
              <div className="our-features__accordion accordion-item">
                <div
                  className="our-features__accordion__header accordion-header collapsed"
                  id="headingSix"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  <div className="our-features__accordion__toggle-icon">
                    <span className="mdi mdi-arrow-right"></span>
                  </div>
                  <button
                    className="our-features__accordion__button heading-6 accordion-button"
                    type="button"
                  >
                    Highlight Post Kamu
                  </button>
                </div>
                <div
                  id="collapseSix"
                  className="our-features__accordion-collapse accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionMediaPost"
                >
                  <p className="body our-features__accordion__body accordion-body">
                    Highlight membantu kamu untuk menyoroti post yang penting untuk diketahui oleh audiensmu
                  </p>
                </div>
              </div>
              <div className="our-features__accordion accordion-item">
                <div
                  className="our-features__accordion__header accordion-header collapsed"
                  id="headingSeven"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  <div className="our-features__accordion__toggle-icon">
                    <span className="mdi mdi-arrow-right"></span>
                  </div>
                  <button
                    className="our-features__accordion__button heading-6 accordion-button"
                    type="button"
                  >
                    Hubungkan Link ke Post Kamu
                  </button>
                </div>
                <div
                  id="collapseSeven"
                  className="our-features__accordion-collapse accordion-collapse collapse"
                  aria-labelledby="headingSeven"
                  data-bs-parent="#accordionMediaPost"
                >
                  <p className="body our-features__accordion__body accordion-body">
                    Berapa banyak pun platform yang kamu gunakan, bisa kamu kumpulkan ke dalam 1 post kamu. Inilah yang membuat segalanya lebih mudah untuk dibagikan.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 our-features__video media-post-video">
            <video
              src={VideoMediaPost}
              height={285}
              autoPlay={true}
              muted={true}
              loop={true}
            ></video>
          </div>
        </div>
      </div>
    </section>
  )
}

export const FeatureMediaPostMobile = () => {
  return (
    <section id="m-featureMediaPost" className="our-features__media-post">
      <div className="container">
        <div className="row flex-a-center">
          <div className="col-12">
            <h2 className="our-features__media-post__title heading-3">
              Feed
            </h2>
          </div>
          <div className="col-12 our-features__video">
            <video
              src={VideoMediaPost}
              height="fit-content"
              autoPlay={true}
              muted={true}
              loop={true}
            ></video>
          </div>
          <div className="col-12">
            <div className="accordion" id="accordionMediaPost">
              <div className="our-features__accordion accordion-item">
                <div
                  className="our-features__accordion__header accordion-header"
                  id="headingFive"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="true"
                  aria-controls="collapseFive"
                >
                  <div className="our-features__accordion__toggle-icon">
                    <span className="mdi mdi-arrow-right"></span>
                  </div>
                  <button
                    className="our-features__accordion__button heading-6 accordion-button"
                    type="button"
                  >
                    Kategorikan Postingan Kamu
                  </button>
                </div>
                <div
                  id="collapseFive"
                  className="our-features__accordion-collapse accordion-collapse collapse show"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionMediaPost"
                >
                  <p className="body our-features__accordion__body accordion-body">
                    Punya banyak post di media sosial dan sulit untuk mengkategorikannya? Disini, kendala itu dapat diselesaikan dengan mudah!
                  </p>
                </div>
              </div>
              <div className="our-features__accordion accordion-item">
                <div
                  className="our-features__accordion__header accordion-header collapsed"
                  id="headingSix"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  <div className="our-features__accordion__toggle-icon">
                    <span className="mdi mdi-arrow-right"></span>
                  </div>
                  <button
                    className="our-features__accordion__button heading-6 accordion-button"
                    type="button"
                  >
                    <i>Highlight</i>&nbsp;Post Kamu
                  </button>
                </div>
                <div
                  id="collapseSix"
                  className="our-features__accordion-collapse accordion-collapse collapse"
                  aria-labelledby="headingSix"
                  data-bs-parent="#accordionMediaPost"
                >
                  <p className="body our-features__accordion__body accordion-body">
                    <i>Highlight</i> membantu kamu untuk menyoroti post yang penting untuk diketahui oleh audiensmu
                  </p>
                </div>
              </div>
              <div className="our-features__accordion accordion-item">
                <div
                  className="our-features__accordion__header accordion-header collapsed"
                  id="headingSeven"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  <div className="our-features__accordion__toggle-icon">
                    <span className="mdi mdi-arrow-right"></span>
                  </div>
                  <button
                    className="our-features__accordion__button heading-6 accordion-button"
                    type="button"
                  >
                    Hubungkan&nbsp;<i>Link</i>&nbsp;ke Post Kamu
                  </button>
                </div>
                <div
                  id="collapseSeven"
                  className="our-features__accordion-collapse accordion-collapse collapse"
                  aria-labelledby="headingSeven"
                  data-bs-parent="#accordionMediaPost"
                >
                  <p className="body our-features__accordion__body accordion-body">
                    Berapa banyak pun platform yang kamu gunakan, bisa kamu kumpulkan ke dalam 1 post kamu. Inilah yang membuat segalanya lebih mudah untuk dibagikan.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
