import React from "react"
import VideoPersonalize from "@videos/feature-personalize.mp4"

export const FeaturePersonalize = () => {
  return (
    <section id="featurePersonalize" className="our-features__personalize">
      <div className="container">
        <div className="row flex-a-center">
          <div className="col-6">
            <h2 className="our-features__personalize__title heading-3">
              <em>Edit Profile</em>
            </h2>
            <div className="accordion" id="accordionPersonalize">
              <div className="our-features__accordion accordion-item">
                <div
                  className="our-features__accordion__header accordion-header"
                  id="headingOne"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <div className="our-features__accordion__toggle-icon">
                    <span className="mdi mdi-arrow-right"></span>
                  </div>
                  <button
                    className="our-features__accordion__button heading-6 accordion-button"
                    type="button"
                  >
                    Atur Profil Sesukamu
                  </button>
                </div>
                <div
                  id="collapseOne"
                  className="our-features__accordion-collapse accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionPersonalize"
                >
                  <p className="body our-features__accordion__body accordion-body">
                    Mulai dari nama hingga deskripsi dapat kamu isi sesuai
                    kebutuhan. Yuk, deskripsikan brandmu sebaik mungkin mulai
                    sekarang!
                  </p>
                </div>
              </div>
              <div className="our-features__accordion accordion-item">
                <div
                  className="our-features__accordion__header accordion-header collapsed"
                  id="headingTwo"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <div className="our-features__accordion__toggle-icon">
                    <span className="mdi mdi-arrow-right"></span>
                  </div>
                  <button
                    className="our-features__accordion__button heading-6 accordion-button"
                    type="button"
                  >
                    Atur Waktu Respon
                  </button>
                </div>
                <div
                  id="collapseTwo"
                  className="our-features__accordion-collapse accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionPersonalize"
                >
                  <p className="body our-features__accordion__body accordion-body">
                    Buat audiensmu semakin nyaman dengan memberi tahu mereka
                    waktu yang paling tepat untuk berkomunikasi denganmu.
                  </p>
                </div>
              </div>
              <div className="our-features__accordion accordion-item">
                <div
                  className="our-features__accordion__header accordion-header collapsed"
                  id="headingThree"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <div className="our-features__accordion__toggle-icon">
                    <span className="mdi mdi-arrow-right"></span>
                  </div>
                  <button
                    className="our-features__accordion__button heading-6 accordion-button"
                    type="button"
                  >
                    Tersedia Berbagai Cover Image
                  </button>
                </div>
                <div
                  id="collapseThree"
                  className="our-features__accordion-collapse accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionPersonalize"
                >
                  <p className="body our-features__accordion__body accordion-body">
                    Kreativitas tanpa batas! Kamu bisa memilih berbagai cover
                    image untuk dipadukan dengan tema dan kategori bisnis
                    ataupun karyamu.
                  </p>
                </div>
              </div>
              <div className="our-features__accordion accordion-item">
                <div
                  className="our-features__accordion__header accordion-header collapsed"
                  id="headingFour"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  <div className="our-features__accordion__toggle-icon">
                    <span className="mdi mdi-arrow-right"></span>
                  </div>
                  <button
                    className="our-features__accordion__button heading-6 accordion-button"
                    type="button"
                  >
                    Tersedia Berbagai Tema Pilihan
                  </button>
                </div>
                <div
                  id="collapseFour"
                  className="our-features__accordion-collapse accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionPersonalize"
                >
                  <p className="body our-features__accordion__body accordion-body">
                    Tema menggambarkan selera. Kamu punya kendali penuh atas
                    bagaimana tampilan pipehome mu sendiri.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 our-features__video personalize-video">
            <video
              src={VideoPersonalize}
              height={285}
              autoPlay={true}
              muted={true}
              loop={true}
            ></video>
          </div>
        </div>
      </div>
    </section>
  )
}

export const FeaturePersonalizeMobile = () => {
  return (
    <section id="m-featurePersonalize" className="our-features__personalize">
      <div className="container">
        <div className="row flex-a-center">
          <div className="col-12">
            <h2 className="our-features__personalize__title heading-3">
              <em>Edit Profile</em>
            </h2>
          </div>
          <div className="col-12 our-features__video">
            <video
              src={VideoPersonalize}
              height="fit-content"
              autoPlay={true}
              muted={true}
              loop={true}
            ></video>
          </div>
          <div className="col-12">
            <div className="accordion" id="accordionPersonalize">
              <div className="our-features__accordion accordion-item">
                <div
                  className="our-features__accordion__header accordion-header"
                  id="headingOne"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <div className="our-features__accordion__toggle-icon">
                    <span className="mdi mdi-arrow-right"></span>
                  </div>
                  <button
                    className="our-features__accordion__button heading-6 accordion-button"
                    type="button"
                  >
                    Atur Profil Sesukamu
                  </button>
                </div>
                <div
                  id="collapseOne"
                  className="our-features__accordion-collapse accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionPersonalize"
                >
                  <p className="body our-features__accordion__body accordion-body">
                    Mulai dari nama hingga deskripsi dapat kamu isi sesuai
                    kebutuhan. Yuk, deskripsikan brandmu sebaik mungkin mulai
                    sekarang!
                  </p>
                </div>
              </div>
              <div className="our-features__accordion accordion-item">
                <div
                  className="our-features__accordion__header accordion-header collapsed"
                  id="headingTwo"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <div className="our-features__accordion__toggle-icon">
                    <span className="mdi mdi-arrow-right"></span>
                  </div>
                  <button
                    className="our-features__accordion__button heading-6 accordion-button"
                    type="button"
                  >
                    Atur Waktu Respon
                  </button>
                </div>
                <div
                  id="collapseTwo"
                  className="our-features__accordion-collapse accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionPersonalize"
                >
                  <p className="body our-features__accordion__body accordion-body">
                    Buat audiensmu semakin nyaman dengan memberi tahu mereka
                    waktu yang paling tepat untuk berkomunikasi denganmu.
                  </p>
                </div>
              </div>
              <div className="our-features__accordion accordion-item">
                <div
                  className="our-features__accordion__header accordion-header collapsed"
                  id="headingThree"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <div className="our-features__accordion__toggle-icon">
                    <span className="mdi mdi-arrow-right"></span>
                  </div>
                  <button
                    className="our-features__accordion__button heading-6 accordion-button"
                    type="button"
                  >
                    Tersedia Berbagai Cover Image
                  </button>
                </div>
                <div
                  id="collapseThree"
                  className="our-features__accordion-collapse accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionPersonalize"
                >
                  <p className="body our-features__accordion__body accordion-body">
                    Kreativitas tanpa batas! Kamu bisa memilih berbagai cover
                    image untuk dipadukan dengan tema dan kategori bisnis
                    ataupun karyamu.
                  </p>
                </div>
              </div>
              <div className="our-features__accordion accordion-item">
                <div
                  className="our-features__accordion__header accordion-header collapsed"
                  id="headingFour"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  <div className="our-features__accordion__toggle-icon">
                    <span className="mdi mdi-arrow-right"></span>
                  </div>
                  <button
                    className="our-features__accordion__button heading-6 accordion-button"
                    type="button"
                  >
                    Tersedia Berbagai Tema Pilihan
                  </button>
                </div>
                <div
                  id="collapseFour"
                  className="our-features__accordion-collapse accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionPersonalize"
                >
                  <p className="body our-features__accordion__body accordion-body">
                    Tema menggambarkan selera. Kamu punya kendali penuh atas
                    bagaimana tampilan pipehome mu sendiri.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
