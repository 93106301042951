import React from "react"
import VideoSocialLinks from "@videos/feature-social-link.mp4"

export const FeatureSocialLinks = () => {
  return (
    <section id="featureSocialLinks" className="our-features__social-links">
      <div className="container">
        <div className="row flex-a-center">
          <div className="col-6 our-features__video social-links-video">
            <video
              src={VideoSocialLinks}
              height={285}
              autoPlay={true}
              muted={true}
              loop={true}
            ></video>
          </div>
          <div className="col-6">
            <h2 className="our-features__social-links__title heading-3">
              Media Sosial
            </h2>
            <p className="our-features__social-links__description body">
              Di era digital ini, media sosial sudah menjadi alat utama untuk berkomuniasi. Karena itulah kami menyediakan fitur untuk mencantumkan link media sosial kamu.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export const FeatureSocialLinksMobile = () => {
  return (
    <section id="m-featureSocialLinks" className="our-features__social-links">
      <div className="container">
        <div className="row flex-a-center">
          <div className="col-12">
            <h2 className="our-features__social-links__title heading-3">
              Media Sosial
            </h2>
          </div>
          <div className="col-12 our-features__video">
            <video
              src={VideoSocialLinks}
              height="fit-content"
              autoPlay={true}
              muted={true}
              loop={true}
            ></video>
          </div>

          <div className="col-12">
            <p className="our-features__social-links__description body">
              Di era digital ini, media sosial sudah menjadi alat utama untuk berkomuniasi. Karena itulah kami menyediakan fitur untuk mencantumkan link media sosial kamu.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
