import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export const FeatureGetstarted = () => {
  return (
    <section className="our-features__get-started container">
      <div className="our-features__get-started__card">
        <div className="row flex-a-center">
          <div className="col-4">
            <StaticImage
              className="our-features__get-started__card__image"
              src="../../images/landing/landing-get-started.png"
              alt="Our Features Get Started"
              width={337}
              height={279}
            />
          </div>
          <div className="col-8">
            <h2 className="our-features__get-started__card__title heading-4">
              Mulai SEKARANG!
            </h2>
            <h3 className="our-features__get-started__card__description subhead">
              Tunggu apa lagi? Yuk buat pipehome kamu
            </h3>
            <Link
              className="our-features__get-started__card__button button button-lg button-secondary"
              to="/register"
            >
              Mulai GRATIS!
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export const FeatureGetstartedMobile = () => {
  return (
    <section className="our-features__get-started container">
      <div className="our-features__get-started__card">
        <div className="row flex-a-center">
          <div className="col-12">
            <StaticImage
              className="our-features__get-started__card__image"
              src="../../images/landing/landing-get-started.png"
              alt="Our Features Get Started"
              placeholder="tracedSVG"
              layout="fullWidth"
            />
          </div>
          <div className="col-12">
            <h2 className="our-features__get-started__card__title heading-4">
              Mulai SEKARANG!
            </h2>
            <h3 className="our-features__get-started__card__description subhead">
              Tunggu apa lagi? Yuk buat pipehome kamu
            </h3>
            <Link
              className="our-features__get-started__card__button button button-lg button-secondary"
              to="/register"
            >
              Mulai GRATIS!
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
