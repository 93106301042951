import * as React from "react"
import Features from "@components/OurFeatures/Features"
import Seo from "@components/seo"

const OurFeaturesPage = () => (
  <>
    <Seo title="Our Features" />
    <Features />
  </>
)

export default OurFeaturesPage
