import React from "react"
import VideoLinks from "@videos/feature-vanilla-link.mp4"

export const FeatureLinks = () => {
  return (
    <section id="featureLinks" className="our-features__links">
      <div className="container">
        <div className="row flex-a-center">
          <div className="col-6 our-features__video links-video">
            <video
              src={VideoLinks}
              height={285}
              autoPlay={true}
              muted={true}
              loop={true}
            ></video>
          </div>
          <div className="col-6">
            <h2 className="our-features__links__title heading-3">Links</h2>
            <p className="our-features__links__description body">
              Link merupakan kekuatan utama untuk <b>menghubungkan pengunjung websitemu</b> ke berbagai platform yang kamu gunakan untuk berjualan maupun memasang karya. Disini kamu dapat menambahkan banyak link sesukamu.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export const FeatureLinksMobile = () => {
  return (
    <section id="m-featureLinks" className="our-features__links">
      <div className="container">
        <div className="row flex-a-center">
          <div className="col-12">
            <h2 className="our-features__links__title heading-3">Links</h2>
          </div>
          <div className="col-12 our-features__video">
            <video
              src={VideoLinks}
              height="fit-content"
              autoPlay={true}
              muted={true}
              loop={true}
            ></video>
          </div>
          <div className="col-12">
            <p className="our-features__links__description body">
              Link merupakan kekuatan utama untuk <b>menghubungkan pengunjung websitemu</b> ke berbagai platform yang kamu gunakan untuk berjualan maupun memasang karya. Disini kamu dapat menambahkan banyak link sesukamu.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
